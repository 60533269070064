import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/Home';
import Navbar from './components/Navbar';
import About from './components/About';
import Services from './components/Services';
import Capabilities from './components/Capabilities';
import Customer from './components/Customer';
import Partners from './components/Partner';
import ContactUs from './components/ContactUs';
import 'aos/dist/aos.css';
import Footer from './components/Footer';

function App() {
  
  return (
    <>
      <Navbar/>
      <Home/>
      <About/>
      <Services/>
      <Capabilities/>
      <Customer/>
      <Partners/>
      <ContactUs/>
      <Footer/>
    </>
  );
}

export default App;
