import React, { useEffect } from "react";
import img1 from "../img/foto1.JPG";
import img2 from "../img/foto2.JPG";
import img3 from "../img/logo4.png";
import img4 from "../img/foto3.JPG";
import AOS from "aos";

const About = () => {
  useEffect(() => {
    AOS.init();
  });

  return (
    <div className="about-section" id="about">
      <div className="container">
      <div className="row no-gutters">
        <div className="col-lg-6">
          <h1 className="mt-4" data-aos="fade-right" data-aos-duration="2000">
            About Us
          </h1>
          <div data-aos="fade-up" data-aos-delay="300" data-aos-duration="2000">
            <h2 className="back-title">
              <strong>About</strong>
            </h2>
            <h2 className="back-subtitle">
              <strong>Us</strong>
            </h2>
          </div>
          <img
            src={img1}
            alt="img-1"
            className="img-1"
            data-aos="fade-down-left"
            data-aos-duration="2000"
          />
          <img
            src={img2}
            alt="img-2"
            className="img-2"
            data-aos="fade-down-right"
            data-aos-duration="2000"
          />
          <h5
            className="vision mt-3"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            vision
          </h5>
          <p
            className="about-desc2"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            "Providing IT network services with international quality and
            Providing Network Service that is ready to use with tangible
            benefits for end-users"
          </p>
          <h5 className="vision" data-aos="fade-up" data-aos-duration="2000">
            mission
          </h5>
          <p
            className="about-desc2"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            "Become the best provider of information and communication
            technology based business solutions"
          </p>
          <h5 className="vision" data-aos="fade-up" data-aos-duration="2000">
            goals
          </h5>
          <p
            className="about-desc2"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            "Your satisfaction and pleasure is our obligation"
          </p>
        </div>
        <div className="col-lg-6">
          <div className="text-right">
            <img
              src={img3}
              alt="img-3"
              className="img-3"
              data-aos="fade-left"
              data-aos-delay="600"
              data-aos-duration="2000"
            />
          </div>
          <p className="about-desc" data-aos="fade-up" data-aos-duration="2000">
            PT. Duta Sarana Abadi Jaya, established on November 13, 2007 is an
            Indonesian based business that specialized in selling
            telecommunication equipments which as the representative of other
            companies.
            Also as the executor of the work of telecommunications equipment
            including but not limited to the manufacture, maintenance, and
            construction of network infrastructure.
          </p>
          <p className="about-desc" data-aos="fade-up" data-aos-duration="2000">
            PT Duta Sarana Abadi Jaya has built its reputation with some
            international big business partners and some local big
            telecommunications operators by providing professional and quality
            services across to the clients as well in cooperation with its
            business partners.
          </p>
          <img
            src={img4}
            alt="img-4"
            className="img-4 mb-3"
            data-aos="fade-up-left"
            data-aos-duration="2000"
          />
        </div>
      </div>
      </div>
    </div>
  );
};

export default About;
