import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import {Link} from "react-scroll";
import logo from "../img/logo4.png"

const Navbar = () => {
const [transparent, setTransparent] = useState(true)

    const changeBg = () => {
        if (window.scrollY >= 60) {
            setTransparent(false)
        } else {
            setTransparent(true)
        }
    }

    useEffect(() => {
        changeBg()
        window.addEventListener("scroll", changeBg)
    })

    return (
        <nav className={transparent ? "navbar navbar-expand-lg navbar-light fixed-top" : "navbar navbar-expand-lg navbar-light bg-dark fixed-top"}>
            <div className="container">
                <Link smooth={true} to="home" offset={-110} className="navbar-brand" href="#">
                    <img src={logo} alt="logo" className="logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <FontAwesomeIcon icon={faBars} style={{color:"#fff"}}/>
                </button>

                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item active">
                            <Link smooth={true} to="home" offset={-110} className="nav-link" href="#">Home <span className="sr-only">(current)</span></Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="about" offset={-110} className="nav-link" href="#">About</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="services" offset={-110} className="nav-link" href="#">Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="capabilities" offset={-110} className="nav-link" href="#">Capabilities</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="customers" offset={-110} className="nav-link" href="#">Customers</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="partners" offset={-110} className="nav-link" href="#">Partners</Link>
                        </li>
                        <li className="nav-item">
                            <Link smooth={true} to="contact-us" offset={-110} className="nav-link" href="#">Contact Us</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
