import React, { useEffect } from 'react'
import AOS from 'aos';

const Services = () => {
    useEffect(() => {
        AOS.init();
      })
    return (
        <div className='service-wrapper bg-dark' id="services">
            <div className='container'>
                <h1 className='text-center text-light mb-5' data-aos="fade-up" data-aos-duration="2000">Services</h1>
                <div className='row' data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
                    <div className='col-md-1'><h2 className='service-num'>01</h2></div>
                    <div className='col-md-5'>
                        <h2><strong>Networking</strong></h2>
                        <p>We provide infrastructure networking installation services. With entry level and enterprise scale. By prioritizing live connectivity. Providing the best technology at the moment we are collaborating with leading vendors such as Juniper Networks.</p>
                    </div>
                    <div className='col-md-1'><h2 className='service-num'>02</h2></div>
                    <div className='col-md-5'>
                        <h2>Network Security</h2>
                        <p>We also provide network security solutions to provide protection against cyberattacks. In this case we are more flexible in building network security, we provide budget solutions to the best values.</p>
                    </div>
                </div>
                <div className='row' data-aos="fade-up" data-aos-delay="150" data-aos-duration="2000">
                <div className='col-md-1'><h2 className='service-num'>03</h2></div>
                    <div className='col-md-5'>
                        <h2>Data Center</h2>
                        <p>Experienced in building Data Centers, we can provide solutions for you. Selection of the best devices of its time. As well as helping the best design for smooth connectivity, is our expertise.</p>
                    </div>
                    <div className='col-md-1'><h2 className='service-num'>04</h2></div>
                    <div className='col-md-5'>
                        <h2>Software Defined Network</h2>
                        <p>With the rapid development of IT infrastructure accompanied by technology that continues to grow. Demand us to always adapt to change. SDN is the best solution for it all.</p>
                    </div>
                </div>
                <div className='row' data-aos="fade-up" data-aos-delay="200" data-aos-duration="2000">
                <div className='col-md-1'><h2 className='service-num'>05</h2></div>
                    <div className='col-md-5'>
                        <h2>Endpoint Security</h2>
                        <p>More and more development in cyber attack viruses, phising, malware are starting to target endpoint. Motivates us to provide the best protection options.</p>
                    </div>
                    <div className='col-md-1'><h2 className='service-num'>06</h2></div>
                    <div className='col-md-5'>
                        <h2>Cabling System</h2>
                        <p>Providing connectivity with a neat design is our main goal, so that our cabling and labeling are the mainstay of the settlement. We provide withdrawal and connection od LAN / FO network cables.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services
