import React from "react";
import kaspersky from "../img/karspersky.png";
import arista from "../img/arista.png";
import juniper from "../img/juniper.png";
import edge from "../img/edgecore.png";
import hillstone from "../img/hillstone.png";
import palo from "../img/pan.jpg";
import dell from "../img/dell.jpg";
import panduit from "../img/pand.png";
import netviel from "../img/netv.png";
import ciena from "../img/Ciena_logo.png";
import h3c from "../img/h3c.jpeg";
import nutanix from "../img/nutanix.png";
import Marquee from "react-double-marquee";

const Partners = () => {
  return (
    <div id="partners" className="partner-wrapper pb-3">
      <div className="container">
        <h1 className="text-center">Partners</h1>
        <div
          style={{
            whiteSpace: "nowrap",
          }}
        >
          <Marquee style={{ whieSpace: "noWrap" }}>
            <img className="ml-4" src={kaspersky} alt="kaspersky" />
            <img className="ml-4" src={arista} alt="arista" style={{height:"45px", width:"auto"}}/>
            <img className="ml-4" src={juniper} alt="juniper" />
            <img className="ml-4" src={edge} alt="edge" />
            <img className="ml-4" src={hillstone} alt="hillstone" />
            <img className="ml-4" src={palo} alt="panloalto" />
            <img className="ml-4" src={dell} alt="dell" />
            <img className="ml-4" src={panduit} alt="panduit" />
            <img className="ml-4" src={netviel} alt="netviel" />
            <img className="ml-4" src={ciena} alt="ciena" />
            <img className="ml-4" src={h3c} alt="h3c" />
            <img className="ml-4" src={nutanix} alt="nutanix" />
          </Marquee>
        </div>
      </div>
    </div>
  );
};

export default Partners;
