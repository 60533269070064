import React, { useEffect } from "react";
import AOS from "aos";
import fast from "../icon/fast.svg";
import precise from "../icon/precise.svg";
import concistence from "../icon/concistence.svg";
import support from "../icon/support.svg";

const Capabilities = () => {
  useEffect(() => {
    AOS.init();
  });
  return (
    <div className="capability-wrapper" id="capabilities">
      <div className="container">
        <h1 className="text-right" data-aos="fade-left" data-aos-delay="150" data-aos-duration="2000">Capabilities</h1>
        <h4 className="text-right" data-aos="fade-left" data-aos-delay="300" data-aos-duration="2000">Why should you choose DSAJ?</h4>
        <div className="row">
          <div className="col-lg-3 offset-lg-6"  id="first-row">
            <div className="card-cap" data-aos="fade-up" data-aos-delay="450" data-aos-duration="2000">
              <div className="row mx-auto">
                <img src={fast} className="icon-cap" alt="fast" />
                <h2 className="card-cap-title mt-2 ml-2">Fast</h2>
              </div>
              <p className="card-cap-desc">
                We can installing your server quickly, so that your bussiness
                runs more smoothly.
              </p>
            </div>
            <div className="card-cap" data-aos="fade-up" data-aos-delay="750" data-aos-duration="2000">
              <div className="row text-center">
                <img src={concistence} className="icon-cap" alt="fast" />
                <h2 className="card-cap-title mt-2 ml-2">Consistence</h2>
              </div>
              <p className="card-cap-desc">
                We always conscist to keep our customer satisfy, with giving a
                stable connection for them.
              </p>
            </div>
          </div>
          <div className="col-lg-3" data-aos="fade-up" data-aos-delay="600" data-aos-duration="2000">
            <div className="card-cap">
              <div className="row text-center">
                <img src={precise} className="icon-cap" alt="fast" />
                <h2 className="card-cap-title mt-2 ml-2">Precision</h2>
              </div>
              <p className="card-cap-desc">
                Cabling neatness is an important key in server installation.
                that way the network accuracy will be better.
              </p>
            </div>
            <div className="card-cap" data-aos="fade-up" data-aos-delay="900" data-aos-duration="2000">
              <div className="row text-center">
                <img src={support} className="icon-cap" alt="fast" />
                <h2 className="card-cap-title mt-2 ml-2">Support</h2>
              </div>
              <p className="card-cap-desc">
                Tell us your problem, and we will support your problem
                Countermeasure.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Capabilities;
