import React, { useState } from 'react'
import {Link} from "react-scroll";

const Home = () => {
    const [cursorX, setCursorX] = useState()
    const [cursorY, setCursorY] = useState()

    const move = (e) => {
        console.log(e.pageX)
            setCursorX(e.pageX)
            setCursorY(e.pageY)
    }

    return (
        <div className='home-wrapper' id='home'>
            <div className='main-info'>
                <h1 className='text-light' data-aos="fade-left" data-aos-delay="400" data-aos-duration="2000"><strong>Get better network system integration for your company</strong></h1>
                <p className='text-light mt-2' data-aos="fade-right" data-aos-delay="800" data-aos-duration="2000">Your satisfaction and pleasure are our obligation</p>
                <div className='btn-subs' onMouseEnter={move} data-aos="zoom-in" data-aos-delay="1200" data-aos-duration="2000"><strong className='text-light'>
                    <Link smooth={true} to="contact-us" offset={-110} className="nav-link" href="#">
                        <span style={{left:cursorX + "px",top:cursorY + "px"}}></span>
                    Subscribe
                    </Link>
                </strong></div>
            </div>
        </div>
    )
}

export default Home
