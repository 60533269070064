import React, { useState } from "react";
import emailJs from "emailjs-com";
import { useForm } from "react-hook-form";

const ContactUs = () => {
  const [successMessage, setSuccessMessage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const serviceID = "service_ID";
  const templateID = "template_ID";
  const userID = "user_EXXxlzj6wkhrvDZ5BlDmY";

  const sendEmail = (serviceID, templateID, variables, userID) => {
    emailJs
      .send(serviceID, templateID, variables, userID)
      .then(() => {
        setSuccessMessage(
          "Form sent successfully! I'll contact you as soon as possible."
        );
      })
      .catch((err) => console.error(`Something went wrong ${err}`));
  };

  const onSubmit = (data, r) => {
    sendEmail(
      serviceID,
      templateID,
      {
        name: data.name,
        email: data.email,
        subject: data.subject,
        message: data.message,
      },
      userID
    );
    r.target.reset();
  };
  return (
    <div id="contact-us" className="contact-wrapper bg-dark">
        <div className="container" style={{paddingTop:"80px"}}>
          <div className="row">
            <div className="col-md-6">
              <h1>Contact Us</h1>
              <h4>Send us message, we will call back later</h4>
              <span className="success-message">{successMessage}</span>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      placeholder="Your Name"
                      aria-invalid={errors.name ? "true" : "false"}
                      {...register("name", {
                        required: "Please enter your name",
                        maxLength: {
                          value: 20,
                          message:
                            "Please enter a name with fewer than 20 characters",
                        },
                      })}
                    />
                    <div className="error-message mt-3">
                      {errors.name && errors.name.message}
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      {...register("email", {
                        required: "Please enter your email",
                      })}
                    />
                    <div className="error-message mt-3">
                      {errors.email && errors.email.message}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    aria-invalid={errors.name ? "true" : "false"}
                    {...register("subject", {
                      required: "OOPS, You forget to add the subject.",
                    })}
                  />
                </div>
                <div className="error-message">
                  {errors.subject && errors.subject.message}
                </div>
                <div className="form-group">
                  <textarea
                    type="text"
                    className="form-control"
                    name="message"
                    placeholder="Message"
                    {...register("message", {
                      required: "Please describe shortly your needs.",
                    })}
                  />
                </div>
                <div className="error-message">
                  {errors.message && errors.message.message}
                </div>
                <button type="submit" className="btn btn-send">
                  send message
                </button>
              </form>
            </div>
            <div className="col-md-6">
              <div className="text-center mt-5">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3966.3001310257664!2d106.83971281477292!3d-6.224100162690764!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f31214bb1a2f%3A0xbe3f68bdbfb5d261!2sPT.%20Duta%20Sarana%20Abadi%20Jaya!5e0!3m2!1sen!2sid!4v1639993563797!5m2!1sen!2sid"
                  width="400"
                  height="330"
                  style={{ border: "0", borderRadius: "5px" }}
                  allowFullScreen=""
                  loading="lazy"
                  title="map"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default ContactUs;
