import React from "react";
import { Link } from "react-scroll";
import logo from "../img/logo4.png";

const Footer = () => {
    console.log()
  return (
    <div className="bg-dark footer">
      <div className="container p-5">
        <div className="row">
          <div className="col-md-4">
            <h3 className="text-light">
              <strong>Company Address</strong>
            </h3>
            <p>
              Office Tower A Eightyeight@Kasablanka 10 Unit E Jl. Casablanca
              Raya Kav.88, Jakarta Selatan 12870
            </p>
          </div>
          <div className="col-md-4 text-center">
            <h3 className="text-light">
              <strong>Call Us</strong>
            </h3>
            <p>021-83709696</p>
          </div>
          <div className="col-md-4 text-center">
            <h3 className="text-light">
              <strong>Sales</strong>
            </h3>
            <p>Andi +62 819-3626-9066</p>
            <p>Petrus +62 812-1322-3332</p>
            <p>Chattra +62 819-9988-8785</p>
          </div>
        </div>
        <div className="d-flex" style={{marginRight:"4%"}}>
        <Link
          smooth={true}
          to="home"
          offset={-110}
          className="navbar-brand"
          href="#"
        >
          <img src={logo} alt="logo" className="logo" />
        </Link>
        <p className="copyright ml-auto my-auto">&copy; {(new Date().getFullYear())} PT. Duta Sarana Abadi Jaya</p>
        </div >      
      </div>
    </div>
  );
};

export default Footer;
