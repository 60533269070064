import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.css";
import AOS from 'aos';
import kemenkes from "../img/kemenkes.png";
import kemendagri from "../img/mendagri.png";
import kemendikbud from "../img/mendikbud.png";
import peruri from "../img/per.png";
import angkasa from "../img/ap2.jpg";
import telkom from "../img/telkom.png";
import bnpt from "../img/bnpt.png";
import panin from "../img/panin.png";

const Customer = () => {
    useEffect(() => {
        AOS.init();
      })

  return (
    <div className="customer-wrapper" id="customers">
      <div className="container">
        <h1 className="text-center" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">Customer</h1>
        <h4 className="text-center text-light" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">We are building trust in enterprise markets</h4>
        <Carousel
          showArrows={false}
          infiniteLoop={true}
          showThumbs={false}
          showStatus={false}
          autoPlay={true}
          interval={3000}
        >
          <div className="row">
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card">
                <img src={kemenkes} alt="kemenkes" className="img-carousel" />
                <div className="myCarousel text-center">
                  <h5>Kementerian Kesehatan RI</h5>
                  <p>
                    Procurement of Rental and Manage Service Routers and Support
                    Devices Online SIKNAS CPE, Extension of Rental and Managed
                    Service Routers and Supporting Devices for Procurement of
                    Internet / VPN Networks at the Ministry of Health.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card text-center">
                <img
                  src={kemendagri}
                  alt="kemendagri"
                  className="img-carousel"
                />
                <div className="myCarousel">
                  <h5>Kementerian Dalam Negeri</h5>
                  <p>
                    installation services and installation of core switches,
                    distribution switches, access switches, firewalls, network
                    monitoring systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card text-center">
                <img
                  src={kemendikbud}
                  alt="kemendikbud"
                  className="img-carousel"
                />
                <div className="myCarousel">
                  <h5>Kemendikbud</h5>
                  <p>
                    Procurement work for increasing data center capacity and
                    procurement work for improving learning house access
                    quality.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card text-center">
                <img src={peruri} alt="peruri" className="img-carousel" />
                <div className="myCarousel">
                  <h5>Peruri</h5>
                  <p>
                    Installation and Procurement Services for Intrusion
                    Prevention System, routers and Core Switches, Core Switch
                    Procurement and Ordering Services at PERUM PERURI.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3"  data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card text-center">
                <img src={angkasa} alt="angkasa" className="img-carousel" />
                <div className="myCarousel">
                  <h5>Angkasa Pura II</h5>
                  <p>
                    installation services and installation of core switches,
                    distribution switches, access switches, firewalls, network
                    monitoring systems.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card text-center">
                <img src={telkom} alt="telkom" className="img-carousel" />
                <div className="myCarousel">
                  <h5>Telkom</h5>
                  <p>
                  L2 Switching procurement and installation of defined network software for easy Data Center operation.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card text-center">
                <img src={panin} alt="panin" className="img-carousel" />
                <div className="myCarousel">
                  <h5>Panin</h5>
                  <p>
                    Providing Face Recognition System for new customer
                    registration with dukcapil matching data from NIK and selfie
                    photo.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3" data-aos="fade-up" data-aos-delay="100" data-aos-duration="2000">
              <div className="carousel-card text-center">
                <img src={bnpt} alt="bnpt" className="img-carousel" />
                <div className="myCarousel">
                  <h5>BNPT</h5>
                  <p>
                    Providing Terorism Countermeasure Information System which
                    is integrated with duckcapil and imigration service.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Customer;
